import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface NavigationState {
  unfoldable: boolean
  outlineFolded: boolean
}

const initialState: NavigationState = {
  unfoldable: false,
  outlineFolded: false,
}

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.unfoldable = !action.payload
    },
    toggleCourseOutline: (state, action: PayloadAction<boolean>) => {
      state.outlineFolded = !action.payload
    },
  },
})

export const { toggleSidebar, toggleCourseOutline } = navigationSlice.actions
export default navigationSlice.reducer
