import React from "react"

import { CSpinner } from "@coreui/react"

export const AppSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CSpinner color="primary" />
    </div>
  )
}
