export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME || ""
export const BASE_ENDPOINT = process.env.REACT_APP_BASE_ENDPOINT || ""
export const WS_BASE_ENDPOINT = process.env.REACT_APP_WS_BASE_ENDPOINT || ""
export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || "KE"
export const DEFAULT_CURRENCY = process.env.REACT_APP_DEFAULT_CURRENCY || "KES"
export const FLUTTERWAVE_KEY = process.env.REACT_APP_FLUTTERWAVE_KEY || ""

export enum OperationMode {
  TUTOR = "tutor",
  SCHOOL = "school",
}

// SHA256 hexdigest 8
export enum COOKIE_KEY {
  access = "D-a033a6c8f6efbb37",
  refresh = "D-1aa9274adbc959ce",
  expiresIn = "D-703b27a397ed24e0",
  user = "D-028c1f5ee1fec75f",
  redirectUrl = "D-f46a54edd87b9618",
}

export const MODE = process.env.REACT_APP_MODE || OperationMode.TUTOR

export const DATE_FORMAT = "do MMM yyyy"
export const TIME_FORMAT = "h:mm aaa"

export const FREQUENCIES = [
  { value: "NONE", label: "Does not repeat" },
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "YEARLY", label: "Yearly" },
]

export const GENDERS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
]

export const TITLES = [
  {
    label: "Mr",
    value: "mr",
  },
  {
    label: "Mrs",
    value: "mrs",
  },
  {
    label: "Miss",
    value: "miss",
  },
  {
    label: "Ms",
    value: "ms",
  },
  {
    label: "Dr",
    value: "dr",
  },
  {
    label: "Professor",
    value: "prof",
  },
]

export const EDUCATION_LEVELS = [
  {
    label: "Diploma",
    value: "diploma",
  },
  {
    label: "Professional",
    value: "professional",
  },
  {
    label: "Bachelors",
    value: "bachelors",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "Doctorates",
    value: "doctorates",
  },
]

export const DEFAULT_AVAILABILITY = {
  sunday: 0,
  monday: 1,
  tuesday: 1,
  wednesday: 1,
  thursday: 1,
  friday: 1,
  saturday: 0,
}
