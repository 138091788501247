import { format, parseISO } from "date-fns"

import { DATE_FORMAT, TIME_FORMAT } from "app-constants"

export { AppSpinner } from "./AppSpinner"

export const formatDate = (
  dateString: string | undefined,
  dateFormat = DATE_FORMAT
) => {
  return dateString ? format(parseISO(dateString), dateFormat) : ""
}

export const formatTime = (
  dateString: string | undefined,
  timeFormat = TIME_FORMAT
) => {
  return dateString ? format(parseISO(dateString), timeFormat) : ""
}

export const secondsToHms = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)

  var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : ""
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : ""
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : ""
  return hDisplay + mDisplay + sDisplay
}

export const getAcronymn = (sentence: string | undefined) => {
  let matches = sentence ? sentence.match(/\b(\w)/g) || [] : []
  return matches.map((c) => c.toUpperCase()).join("")
}

export const toSentenceCase = (str: string) => {
  if (!str) {
    return ""
  }
  return str
    .trim()
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
      return c.toUpperCase()
    })
}

export const toTitleCase = (str: string) => {
  if (!str) {
    return ""
  }
  var i,
    frags = str.split("_")
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

export const hexToRgb = (hex: string) => {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16))

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16))

  return null
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const insertAt = (arr: any[], index: number, newItem: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
]

export const groupBy = (keys: string[]) => (array: any[]) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join("-")
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const isPropValuesEqual = (
  subject: { [x: string]: any },
  target: { [x: string]: any },
  propNames: any[]
) =>
  propNames.every(
    (propName: string | number) => subject[propName] === target[propName]
  )

export const getUniqueItemsByProperties = (
  items: any[],
  propNames: Iterable<unknown> | ArrayLike<unknown>
) => {
  const propNamesArray = Array.from(propNames)
  if (!items) {
    return []
  }

  return items.filter(
    (item: any, index: any, array: any[]) =>
      index ===
      array.findIndex((foundItem: any) =>
        isPropValuesEqual(foundItem, item, propNamesArray)
      )
  )
}

export const waitForOpenConnection = (socket: WebSocket) => {
  return new Promise<void>((resolve, reject) => {
    const maxNumberOfAttempts = 10
    const intervalTime = 200 //ms

    let currentAttempt = 0
    const interval = setInterval(() => {
      if (currentAttempt > maxNumberOfAttempts - 1) {
        clearInterval(interval)
        reject(new Error("Maximum number of attempts exceeded"))
      } else if (socket.readyState === socket.OPEN) {
        clearInterval(interval)
        resolve()
      }
      currentAttempt++
    }, intervalTime)
  })
}

export const truncateString = (str: string, n: number) => {
  if (str.length > n) {
    return str.substring(0, n) + "..."
  } else {
    return str
  }
}

export const isString = (x: any) => {
  return Object.prototype.toString.call(x) === "[object String]"
}

export const selectCustomStyles = {
  control: () => ({
    padding: "0.75rem 1.25rem",
    height: "calc(1.5em + 1.5rem + 2px)",
    alignItems: "center",
    borderColor: "#ebeded",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    display: "flex",
    flex: "wrap",
    justifyContent: "space-between",
  }),
  input: (props: any) => ({
    ...props,
    color: "#4f4f4f",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (props: any) => ({ ...props, padding: 0 }),
}

export const removeEmpty = (obj: Object) => {
  const newObj = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (v === Object(v)) {
      /* @ts-ignore */
      newObj[k] = removeEmpty(v)
    } else if (v != null && v) {
      /* @ts-ignore */
      newObj[k] = obj[k]
    }
  })
  return newObj
}
