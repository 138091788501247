import React, { useRef, useState } from "react"

import { SOption } from "types"

const KEY_CODES = {
  DOWN: "ArrowDown",
  UP: "ArrowUp",
  PAGE_DOWN: "PageDown",
  ESCAPE: "Escape",
  PAGE_UP: "PageUp",
  ENTER: "Enter",
}

type AutoComplete = {
  delay?: number
  source: (searchTerm: string) => any
  onChange: (e: any) => void
}

export const useAutoComplete = ({
  delay = 500,
  source,
  onChange,
}: AutoComplete) => {
  const listRef = useRef<HTMLUListElement>(null)

  const [myTimeout, setMyTimeOut] = useState(setTimeout(() => {}, 0))
  const [suggestions, setSuggestions] = useState<SOption[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [textValue, setTextValue] = useState("")

  const delayInvoke = (cb: () => void) => {
    if (myTimeout) {
      clearTimeout(myTimeout)
    }
    setMyTimeOut(setTimeout(cb, delay))
  }

  const selectOption = (index: number) => {
    if (index > -1) {
      onChange(suggestions[index])
      setTextValue(suggestions[index].label)
    }
    clearSuggestions()
  }

  const getSuggestions = async (searchTerm: string) => {
    if (searchTerm && source) {
      const options = await source(searchTerm)
      setSuggestions(options)
    }
  }

  const clearSuggestions = () => {
    setSuggestions([])
    setSelectedIndex(-1)
  }

  const onTextChange = (searchTerm: string) => {
    setLoading(true)
    setTextValue(searchTerm)
    clearSuggestions()
    delayInvoke(() => {
      getSuggestions(searchTerm)
      setLoading(false)
    })
  }

  const optionHeight = listRef?.current?.children[0]?.clientHeight

  const scrollUp = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
    if (listRef?.current?.scrollTop && optionHeight) {
      listRef.current.scrollTop -= optionHeight
    }
  }

  const scrollDown = () => {
    if (selectedIndex < suggestions.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    }
    if (listRef?.current?.scrollTop && optionHeight) {
      listRef.current.scrollTop = selectedIndex * optionHeight
    }
  }

  const pageDown = () => {
    setSelectedIndex(suggestions.length - 1)
    if (listRef?.current?.scrollTop && optionHeight) {
      listRef.current.scrollTop = suggestions.length * optionHeight
    }
  }

  const pageUp = () => {
    setSelectedIndex(0)
    if (listRef?.current?.scrollTop && optionHeight) {
      listRef.current.scrollTop = 0
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      [KEY_CODES.ENTER]: () => selectOption(selectedIndex),
      [KEY_CODES.ESCAPE]: clearSuggestions,
      [KEY_CODES.PAGE_DOWN]: pageDown,
      [KEY_CODES.PAGE_UP]: pageUp,
    }
    if (keyOperation[e.key]) {
      keyOperation[e.key]()
    } else {
      setSelectedIndex(-1)
    }
  }

  const onReset = () => {
    setLoading(false)
    setTextValue("")
    clearSuggestions()
  }

  return {
    bindOption: {
      onClick: (e: any) => {
        let nodes = Array.from(listRef?.current?.children || [])
        selectOption(nodes.indexOf(e.target.closest("li")))
      },
    },
    bindInput: {
      value: textValue,
      onChange: (e: any) => onTextChange(e.target.value),
      onKeyDown,
    },
    bindOptions: {
      ref: listRef,
    },
    loading,
    suggestions,
    selectedIndex,
    textValue,
    onReset,
  }
}
