import axios from "axios"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "app-constants"
import {
  ApiStatus,
  ApiPaginatedResponse,
  ApiError,
  Course,
  Topic,
  StudentAnswer,
  ApiAction,
  Page,
  StudentProgress,
  Assignment,
  Submission,
  Question,
  Quiz,
  Exercise,
  Test,
  StudentStats,
  StatusResponse,
} from "types"

export interface CoursesState {
  course: Course
  courses: ApiPaginatedResponse<Course>
  topics: ApiPaginatedResponse<Topic>
  topic: Topic
  pages: ApiPaginatedResponse<Page>
  page: Page
  answers: { [question: string]: StudentAnswer }
  progress: ApiPaginatedResponse<StudentProgress>
  courseStudentStats: StudentStats
  quiz: Quiz
  exercise: Exercise
  test: Test
  assignments: ApiPaginatedResponse<Assignment>
  assignment: Assignment
  submissions: { [assignmentId: string]: ApiPaginatedResponse<Submission> }
  questions: ApiPaginatedResponse<Question>
  joinedCourse: boolean
  requestedCourse: boolean
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: CoursesState = {
  course: {} as Course,
  courses: {} as ApiPaginatedResponse<Course>,
  topics: {} as ApiPaginatedResponse<Topic>,
  topic: {} as Topic,
  pages: {} as ApiPaginatedResponse<Page>,
  page: {} as Page,
  answers: {},
  progress: {} as ApiPaginatedResponse<StudentProgress>,
  courseStudentStats: {} as StudentStats,
  quiz: {} as Quiz,
  exercise: {} as Exercise,
  test: {} as Test,
  assignments: {} as ApiPaginatedResponse<Assignment>,
  assignment: {} as Assignment,
  submissions: {},
  questions: {} as ApiPaginatedResponse<Question>,
  joinedCourse: false,
  requestedCourse: false,
  apiStatus: {},
  apiError: {},
}

// Courses

export type ListCoursesArgs = {
  search?: string
  school?: string
  teacher?: string
  levels?: string
  teacher__user__gender?: string
  hourly_charge_rate__gte?: number
  hourly_charge_rate__lte?: number
  age_range?: string
  availability?: object
  page?: number
  page_size?: number
  user_id?: string
  recommended?: boolean
}

export const listCourses = createAsyncThunk(
  "courses/listCourses",
  async (args: ListCoursesArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Course>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getCourse = createAsyncThunk(
  "courses/getCourse",
  async (courseId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${courseId}/`
    try {
      const response = await axios.get<Course>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createCourse = createAsyncThunk(
  "courses/createCourse",
  async (args: Course & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args?.levels_ids?.length) {
      formData.append("levels_ids", args.levels_ids.join(","))
    }
    if (args.cover) {
      formData.append("cover", args.cover)
    }
    if (args?.teacher_id) {
      formData.append("teacher_id", args.teacher_id)
    }
    if (args?.assistant_teachers_ids?.length) {
      formData.append(
        "assistant_teachers_ids",
        args.assistant_teachers_ids.join(",")
      )
    }
    if (args.is_elective !== undefined) {
      formData.append("is_elective", String(args.is_elective))
    }
    if (args.hourly_charge_rate !== undefined) {
      formData.append("hourly_charge_rate", String(args.hourly_charge_rate))
    }
    formData.append("school", String(args.school))

    try {
      const response = await axios.post<Course>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateCourse = createAsyncThunk(
  "courses/updateCourse",
  async (args: Course & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args?.id}/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args?.levels_ids?.length) {
      formData.append("levels_ids", args.levels_ids.join(","))
    }
    if (args.cover) {
      formData.append("cover", args.cover)
    }
    if (args?.teacher_id) {
      formData.append("teacher_id", args.teacher_id)
    }
    if (args?.assistant_teachers_ids?.length) {
      formData.append(
        "assistant_teachers_ids",
        args.assistant_teachers_ids.join(",")
      )
    }
    if (args.is_elective !== undefined) {
      formData.append("is_elective", String(args.is_elective))
    }
    if (args.hourly_charge_rate !== undefined) {
      formData.append("hourly_charge_rate", String(args.hourly_charge_rate))
    }
    formData.append("school", String(args.school))

    try {
      const response = await axios.patch<Course>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Topics

export const listTopics = createAsyncThunk(
  "courses/listTopics",
  async (courseId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${courseId}/topics/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Topic>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailTopicArgs = {
  courseId: string
  topicId: string
}

export const getTopic = createAsyncThunk(
  "courses/getTopic",
  async ({ courseId, topicId }: DetailTopicArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${courseId}/topics/${topicId}/`
    try {
      const response = await axios.get<Topic>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createTopic = createAsyncThunk(
  "courses/createTopic",
  async (args: Topic & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args.notes) {
      formData.append("notes", args.notes)
    }
    formData.append("course", args.course)
    formData.append("parent_topic", args.parent_topic)
    try {
      const response = await axios.post<Topic>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateTopic = createAsyncThunk(
  "courses/updateTopic",
  async (args: Topic & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.id}/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args.notes) {
      formData.append("notes", args.notes)
    }
    formData.append("course", args.course)
    formData.append("parent_topic", args.parent_topic)
    try {
      const response = await axios.patch<Topic>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Pages

type ListPagesArgs = {
  courseId: string
  page?: number
  page_size?: number
}

export const listPages = createAsyncThunk(
  "courses/listPages",
  async (args: ListPagesArgs, { rejectWithValue }) => {
    const { courseId, page, page_size } = args
    const url = `${BASE_ENDPOINT}/courses/${courseId}/pages/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Page>>(url, {
        params: { page, page_size },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createPage = createAsyncThunk(
  "courses/createPage",
  async (args: Page & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/pages/`
    try {
      const response = await axios.post<Page>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updatePage = createAsyncThunk(
  "courses/updatePage",
  async (args: Page & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/pages/${args.id}/`
    try {
      const response = await axios.patch<Page>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailPageArgs = {
  courseId: string
  pageId: string
}

export const deletePage = createAsyncThunk(
  "courses/deletePage",
  async (args: DetailPageArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/pages/${args.pageId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Questions

type ListQuestionsArgs = {
  courseId: string
  page?: number
  page_size?: number
}

export const listQuestions = createAsyncThunk(
  "courses/listQuestions",
  async (args: ListQuestionsArgs, { rejectWithValue }) => {
    const { courseId, page, page_size } = args
    const url = `${BASE_ENDPOINT}/courses/${courseId}/questions/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Question>>(url, {
        params: { page, page_size },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createQuestion = createAsyncThunk(
  "courses/createQuestion",
  async (args: Question & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/questions/`
    try {
      const response = await axios.post<Question>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateQuestion = createAsyncThunk(
  "courses/updateQuestion",
  async (args: Question & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/questions/${args.id}/`
    try {
      const response = await axios.patch<Question>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Quizzes

type DetailQuizArgs = {
  courseId: string
  topicId: string
  quizId: string
}

export const getQuiz = createAsyncThunk(
  "courses/getQuiz",
  async (args: DetailQuizArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/quizzes/${args.quizId}/`
    try {
      const response = await axios.get<Quiz>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createQuiz = createAsyncThunk(
  "courses/createQuiz",
  async (args: Quiz & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/quizzes/`
    try {
      const response = await axios.post<Quiz>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateQuiz = createAsyncThunk(
  "courses/updateQuiz",
  async (args: Quiz & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/quizzes/${args.id}/`
    try {
      const response = await axios.patch<Quiz>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteQuiz = createAsyncThunk(
  "courses/deleteQuiz",
  async (args: DetailQuizArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/quizzes/${args.quizId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Exercises

type DetailExerciseArgs = {
  courseId: string
  topicId: string
  exerciseId: string
}

export const getExercise = createAsyncThunk(
  "courses/getExercise",
  async (args: DetailExerciseArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/exercises/${args.exerciseId}/`
    try {
      const response = await axios.get<Exercise>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createExercise = createAsyncThunk(
  "courses/createExercise",
  async (args: Exercise & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/exercises/`
    try {
      const response = await axios.post<Exercise>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateExercise = createAsyncThunk(
  "courses/updateExercise",
  async (args: Exercise & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/exercises/${args.id}/`
    try {
      const response = await axios.patch<Exercise>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteExercise = createAsyncThunk(
  "courses/deleteExercise",
  async (args: DetailExerciseArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/exercises/${args.exerciseId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Tests

type DetailTestArgs = {
  courseId: string
  topicId: string
  testId: string
}

export const getTest = createAsyncThunk(
  "courses/getTest",
  async (args: DetailTestArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/tests/${args.testId}/`
    try {
      const response = await axios.get<Test>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createTest = createAsyncThunk(
  "courses/createTest",
  async (args: Test & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/tests/`
    try {
      const response = await axios.post<Test>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateTest = createAsyncThunk(
  "courses/updateTest",
  async (args: Test & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/tests/${args.id}/`
    try {
      const response = await axios.patch<Test>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteTest = createAsyncThunk(
  "courses/deleteTest",
  async (args: DetailTestArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/tests/${args.testId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Assignments

type ListAssignmentsArgs = {
  courseId: string
  page?: number
  page_size?: number
}

export const listAssignments = createAsyncThunk(
  "courses/listAssignments",
  async (args: ListAssignmentsArgs, { rejectWithValue }) => {
    const { courseId, page, page_size } = args
    const url = `${BASE_ENDPOINT}/courses/${courseId}/assignments/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Assignment>>(url, {
        params: { page, page_size },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailAssignmentArgs = {
  courseId: string
  topicId: string
  assignmentId: string
}

export const getAssignment = createAsyncThunk(
  "courses/getAssignment",
  async (args: DetailAssignmentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/assignments/${args.assignmentId}/`
    try {
      const response = await axios.get<Assignment>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createAssignment = createAsyncThunk(
  "courses/createAssignment",
  async (args: Assignment & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/assignments/`
    try {
      const response = await axios.post<Assignment>(url, args)
      args.onFulfill({ id: response.data.id!, item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateAssignment = createAsyncThunk(
  "courses/updateAssignment",
  async (args: Assignment & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/topics/${args.topic}/assignments/${args.id}/`
    try {
      const response = await axios.patch<Assignment>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteAssignment = createAsyncThunk(
  "courses/deleteAssignment",
  async (args: DetailAssignmentArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/topics/${args.topicId}/assignments/${args.assignmentId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Students

export const submitStudentAnswers = createAsyncThunk(
  "courses/submitStudentAnswers",
  async (args: StudentAnswer & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/students/${args.student}/answers/`
    try {
      const response = await axios.post<StudentAnswer>(url, args)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type ListStudentAnswersArgs = {
  course: string
  student: string
  question: string
  object_id: string
}

export const listStudentAnswers = createAsyncThunk(
  "courses/listStudentAnswers",
  async (args: ListStudentAnswersArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/students/${args.student}/answers/`
    const { question, object_id } = args
    try {
      const response = await axios.get<ApiPaginatedResponse<StudentAnswer>>(
        url,
        { params: { question, object_id } }
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type CourseStudentArgs = {
  course: string
  student: string
}

export const listStudentProgress = createAsyncThunk(
  "courses/listStudentProgress",
  async (args: CourseStudentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/students/${args.student}/progress/`
    try {
      const response = await axios.get<ApiPaginatedResponse<StudentProgress>>(
        url
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getCourseStudentStats = createAsyncThunk(
  "courses/getCourseStudentStats",
  async (args: CourseStudentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/students/${args.student}/stats/`
    try {
      const response = await axios.get<StudentStats>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Submissions

type DetailSubmissionsArgs = {
  courseId: string
  studentId: string
  assignmentId?: string
}

export const listSubmissions = createAsyncThunk(
  "courses/listSubmissions",
  async (args: DetailSubmissionsArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/students/${args.studentId}/submissions/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Submission>>(url, {
        params: { assignment: args.assignmentId },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const submitAssignment = createAsyncThunk(
  "courses/submitAssignment",
  async (args: Submission & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.course}/students/${args.student}/submissions/`
    const formData = new FormData()
    formData.append("student", args.student)
    formData.append("assignment", args.assignment)
    if (args.text_submission) {
      formData.append("text_submission", args.text_submission)
    }
    if (args.file_submission) {
      formData.append("file_submission", args.file_submission)
    }
    formData.append("course", args.course)

    try {
      const response = await axios.post<Submission>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type UserCourseArgs = {
  userId: string
  courseId: string
}

export const hasJoinedCourse = createAsyncThunk(
  "courses/hasJoinedCourse",
  async (args: UserCourseArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/has_joined_course/`
    try {
      const response = await axios.get<StatusResponse>(url, {
        params: { user_id: args.userId },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const hasRequestedCourse = createAsyncThunk(
  "courses/hasRequestedCourse",
  async (args: UserCourseArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/courses/${args.courseId}/has_requested_course/`
    try {
      const response = await axios.get<StatusResponse>(url, {
        params: { user_id: args.userId },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listCourses.pending, (state, action) => {
        state.apiStatus["listCourses"] = ApiStatus.PENDING
      })
      .addCase(listCourses.fulfilled, (state, action) => {
        state.courses = action.payload as ApiPaginatedResponse<Course>
        state.apiStatus["listCourses"] = ApiStatus.FULFILLED
      })
      .addCase(listCourses.rejected, (state, action) => {
        state.apiError["listCourses"] = action.payload as ApiError
        state.apiStatus["listCourses"] = ApiStatus.REJECTED
      })

      .addCase(getCourse.pending, (state, action) => {
        state.apiStatus["getCourse"] = ApiStatus.PENDING
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.course = action.payload as Course
        state.apiStatus["getCourse"] = ApiStatus.FULFILLED
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.apiError["getCourse"] = action.payload as ApiError
        state.apiStatus["getCourse"] = ApiStatus.REJECTED
      })

      .addCase(createCourse.pending, (state, action) => {
        state.apiStatus["createCourse"] = ApiStatus.PENDING
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.course = action.payload as Course
        state.apiStatus["createCourse"] = ApiStatus.FULFILLED
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.apiError["createCourse"] = action.payload as ApiError
        state.apiStatus["createCourse"] = ApiStatus.REJECTED
      })

      .addCase(updateCourse.pending, (state, action) => {
        state.apiStatus["updateCourse"] = ApiStatus.PENDING
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        state.course = action.payload as Course
        state.apiStatus["updateCourse"] = ApiStatus.FULFILLED
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.apiError["updateCourse"] = action.payload as ApiError
        state.apiStatus["updateCourse"] = ApiStatus.REJECTED
      })

      // Topics

      .addCase(listTopics.pending, (state, action) => {
        state.apiStatus["listTopics"] = ApiStatus.PENDING
      })
      .addCase(listTopics.fulfilled, (state, action) => {
        state.topics = action.payload as ApiPaginatedResponse<Topic>
        state.apiStatus["listTopics"] = ApiStatus.FULFILLED
      })
      .addCase(listTopics.rejected, (state, action) => {
        state.apiError["listTopics"] = action.payload as ApiError
        state.apiStatus["listTopics"] = ApiStatus.REJECTED
      })

      .addCase(getTopic.pending, (state, action) => {
        state.apiStatus["getTopic"] = ApiStatus.PENDING
      })
      .addCase(getTopic.fulfilled, (state, action) => {
        state.topic = action.payload as Topic
        state.apiStatus["getTopic"] = ApiStatus.FULFILLED
      })
      .addCase(getTopic.rejected, (state, action) => {
        state.apiError["getTopic"] = action.payload as ApiError
        state.apiStatus["getTopic"] = ApiStatus.REJECTED
      })

      .addCase(createTopic.pending, (state, action) => {
        state.apiStatus["createTopic"] = ApiStatus.PENDING
      })
      .addCase(createTopic.fulfilled, (state, action) => {
        state.topic = action.payload as Topic
        state.course.topics = [...state.course.topics!, state.topic]
        state.apiStatus["createTopic"] = ApiStatus.FULFILLED
      })
      .addCase(createTopic.rejected, (state, action) => {
        state.apiError["createTopic"] = action.payload as ApiError
        state.apiStatus["createTopic"] = ApiStatus.REJECTED
      })

      .addCase(updateTopic.pending, (state, action) => {
        state.apiStatus["updateTopic"] = ApiStatus.PENDING
      })
      .addCase(updateTopic.fulfilled, (state, action) => {
        state.topic = action.payload as Topic
        state.course.topics = [
          state.topic,
          ...state.course.topics!.filter((t) => t.id !== state.topic.id),
        ]
        state.apiStatus["updateTopic"] = ApiStatus.FULFILLED
      })
      .addCase(updateTopic.rejected, (state, action) => {
        state.apiError["updateTopic"] = action.payload as ApiError
        state.apiStatus["updateTopic"] = ApiStatus.REJECTED
      })

      // Pages

      .addCase(listPages.pending, (state, action) => {
        state.apiStatus[`listPages-${action.meta.arg.courseId}`] =
          ApiStatus.PENDING
      })
      .addCase(listPages.fulfilled, (state, action) => {
        state.pages = action.payload as ApiPaginatedResponse<Page>
        state.apiStatus[`listPages-${action.meta.arg.courseId}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listPages.rejected, (state, action) => {
        state.apiError[`listPages-${action.meta.arg.courseId}`] =
          action.payload as ApiError
        state.apiStatus[`listPages-${action.meta.arg.courseId}`] =
          ApiStatus.REJECTED
      })

      .addCase(createPage.pending, (state, action) => {
        state.apiStatus["createPage"] = ApiStatus.PENDING
      })
      .addCase(createPage.fulfilled, (state, action) => {
        state.page = action.payload as Page
        state.apiStatus["createPage"] = ApiStatus.FULFILLED
      })
      .addCase(createPage.rejected, (state, action) => {
        state.apiError["createPage"] = action.payload as ApiError
        state.apiStatus["createPage"] = ApiStatus.REJECTED
      })

      .addCase(updatePage.pending, (state, action) => {
        state.apiStatus["updatePage"] = ApiStatus.PENDING
      })
      .addCase(updatePage.fulfilled, (state, action) => {
        state.page = action.payload as Page
        state.apiStatus["updatePage"] = ApiStatus.FULFILLED
      })
      .addCase(updatePage.rejected, (state, action) => {
        state.apiError["updatePage"] = action.payload as ApiError
        state.apiStatus["updatePage"] = ApiStatus.REJECTED
      })

      .addCase(deletePage.pending, (state, action) => {
        state.apiStatus["deletePage"] = ApiStatus.PENDING
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        state.apiStatus["deletePage"] = ApiStatus.FULFILLED
      })
      .addCase(deletePage.rejected, (state, action) => {
        state.apiError["deletePage"] = action.payload as ApiError
        state.apiStatus["deletePage"] = ApiStatus.REJECTED
      })

      // Questions

      .addCase(listQuestions.pending, (state, action) => {
        state.apiStatus[`listQuestions-${action.meta.arg.courseId}`] =
          ApiStatus.PENDING
      })
      .addCase(listQuestions.fulfilled, (state, action) => {
        state.questions = action.payload as ApiPaginatedResponse<Question>
        state.apiStatus[`listQuestions-${action.meta.arg.courseId}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listQuestions.rejected, (state, action) => {
        state.apiError[`listQuestions-${action.meta.arg.courseId}`] =
          action.payload as ApiError
        state.apiStatus[`listQuestions-${action.meta.arg.courseId}`] =
          ApiStatus.REJECTED
      })

      .addCase(createQuestion.pending, (state, action) => {
        state.apiStatus["createQuestion"] = ApiStatus.PENDING
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.apiStatus["createQuestion"] = ApiStatus.FULFILLED
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.apiError["createQuestion"] = action.payload as ApiError
        state.apiStatus["createQuestion"] = ApiStatus.REJECTED
      })

      .addCase(updateQuestion.pending, (state, action) => {
        state.apiStatus["updateQuestion"] = ApiStatus.PENDING
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.apiStatus["updateQuestion"] = ApiStatus.FULFILLED
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.apiError["updateQuestion"] = action.payload as ApiError
        state.apiStatus["updateQuestion"] = ApiStatus.REJECTED
      })

      // Quizzes

      .addCase(getQuiz.pending, (state, action) => {
        state.apiStatus["getQuiz"] = ApiStatus.PENDING
      })
      .addCase(getQuiz.fulfilled, (state, action) => {
        state.quiz = action.payload as Quiz
        state.apiStatus["getQuiz"] = ApiStatus.FULFILLED
      })
      .addCase(getQuiz.rejected, (state, action) => {
        state.apiError["getQuiz"] = action.payload as ApiError
        state.apiStatus["getQuiz"] = ApiStatus.REJECTED
      })

      .addCase(createQuiz.pending, (state, action) => {
        state.apiStatus["createQuiz"] = ApiStatus.PENDING
      })
      .addCase(createQuiz.fulfilled, (state, action) => {
        state.quiz = action.payload as Quiz
        state.apiStatus["createQuiz"] = ApiStatus.FULFILLED
      })
      .addCase(createQuiz.rejected, (state, action) => {
        state.apiError["createQuiz"] = action.payload as ApiError
        state.apiStatus["createQuiz"] = ApiStatus.REJECTED
      })

      .addCase(updateQuiz.pending, (state, action) => {
        state.apiStatus["updateQuiz"] = ApiStatus.PENDING
      })
      .addCase(updateQuiz.fulfilled, (state, action) => {
        state.quiz = action.payload as Quiz
        state.apiStatus["updateQuiz"] = ApiStatus.FULFILLED
      })
      .addCase(updateQuiz.rejected, (state, action) => {
        state.apiError["updateQuiz"] = action.payload as ApiError
        state.apiStatus["updateQuiz"] = ApiStatus.REJECTED
      })

      .addCase(deleteQuiz.pending, (state, action) => {
        state.apiStatus["deleteQuiz"] = ApiStatus.PENDING
      })
      .addCase(deleteQuiz.fulfilled, (state, action) => {
        state.apiStatus["deleteQuiz"] = ApiStatus.FULFILLED
      })
      .addCase(deleteQuiz.rejected, (state, action) => {
        state.apiError["deleteQuiz"] = action.payload as ApiError
        state.apiStatus["deleteQuiz"] = ApiStatus.REJECTED
      })

      // Exercises

      .addCase(getExercise.pending, (state, action) => {
        state.apiStatus["getExercise"] = ApiStatus.PENDING
      })
      .addCase(getExercise.fulfilled, (state, action) => {
        state.exercise = action.payload as Exercise
        state.apiStatus["getExercise"] = ApiStatus.FULFILLED
      })
      .addCase(getExercise.rejected, (state, action) => {
        state.apiError["getExercise"] = action.payload as ApiError
        state.apiStatus["getExercise"] = ApiStatus.REJECTED
      })

      .addCase(createExercise.pending, (state, action) => {
        state.apiStatus["createExercise"] = ApiStatus.PENDING
      })
      .addCase(createExercise.fulfilled, (state, action) => {
        state.exercise = action.payload as Exercise
        state.apiStatus["createExercise"] = ApiStatus.FULFILLED
      })
      .addCase(createExercise.rejected, (state, action) => {
        state.apiError["createExercise"] = action.payload as ApiError
        state.apiStatus["createExercise"] = ApiStatus.REJECTED
      })

      .addCase(updateExercise.pending, (state, action) => {
        state.apiStatus["updateExercise"] = ApiStatus.PENDING
      })
      .addCase(updateExercise.fulfilled, (state, action) => {
        state.exercise = action.payload as Exercise
        state.apiStatus["updateExercise"] = ApiStatus.FULFILLED
      })
      .addCase(updateExercise.rejected, (state, action) => {
        state.apiError["updateExercise"] = action.payload as ApiError
        state.apiStatus["updateExercise"] = ApiStatus.REJECTED
      })

      .addCase(deleteExercise.pending, (state, action) => {
        state.apiStatus["deleteExercise"] = ApiStatus.PENDING
      })
      .addCase(deleteExercise.fulfilled, (state, action) => {
        state.apiStatus["deleteExercise"] = ApiStatus.FULFILLED
      })
      .addCase(deleteExercise.rejected, (state, action) => {
        state.apiError["deleteExercise"] = action.payload as ApiError
        state.apiStatus["deleteExercise"] = ApiStatus.REJECTED
      })

      // Tests

      .addCase(getTest.pending, (state, action) => {
        state.apiStatus["getTest"] = ApiStatus.PENDING
      })
      .addCase(getTest.fulfilled, (state, action) => {
        state.test = action.payload as Test
        state.apiStatus["getTest"] = ApiStatus.FULFILLED
      })
      .addCase(getTest.rejected, (state, action) => {
        state.apiError["getTest"] = action.payload as ApiError
        state.apiStatus["getTest"] = ApiStatus.REJECTED
      })

      .addCase(createTest.pending, (state, action) => {
        state.apiStatus["createTest"] = ApiStatus.PENDING
      })
      .addCase(createTest.fulfilled, (state, action) => {
        state.test = action.payload as Test
        state.apiStatus["createTest"] = ApiStatus.FULFILLED
      })
      .addCase(createTest.rejected, (state, action) => {
        state.apiError["createTest"] = action.payload as ApiError
        state.apiStatus["createTest"] = ApiStatus.REJECTED
      })

      .addCase(updateTest.pending, (state, action) => {
        state.apiStatus["updateTest"] = ApiStatus.PENDING
      })
      .addCase(updateTest.fulfilled, (state, action) => {
        state.test = action.payload as Test
        state.apiStatus["updateTest"] = ApiStatus.FULFILLED
      })
      .addCase(updateTest.rejected, (state, action) => {
        state.apiError["updateTest"] = action.payload as ApiError
        state.apiStatus["updateTest"] = ApiStatus.REJECTED
      })

      .addCase(deleteTest.pending, (state, action) => {
        state.apiStatus["deleteTest"] = ApiStatus.PENDING
      })
      .addCase(deleteTest.fulfilled, (state, action) => {
        state.apiStatus["deleteTest"] = ApiStatus.FULFILLED
      })
      .addCase(deleteTest.rejected, (state, action) => {
        state.apiError["deleteTest"] = action.payload as ApiError
        state.apiStatus["deleteTest"] = ApiStatus.REJECTED
      })

      // Assignments

      .addCase(listAssignments.pending, (state, action) => {
        state.apiStatus[`listAssignments-${action.meta.arg.courseId}`] =
          ApiStatus.PENDING
      })
      .addCase(listAssignments.fulfilled, (state, action) => {
        state.assignments = action.payload as ApiPaginatedResponse<Assignment>
        state.apiStatus[`listAssignments-${action.meta.arg.courseId}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listAssignments.rejected, (state, action) => {
        state.apiError[`listAssignments-${action.meta.arg.courseId}`] =
          action.payload as ApiError
        state.apiStatus[`listAssignments-${action.meta.arg.courseId}`] =
          ApiStatus.REJECTED
      })

      .addCase(getAssignment.pending, (state, action) => {
        state.apiStatus["getAssignment"] = ApiStatus.PENDING
      })
      .addCase(getAssignment.fulfilled, (state, action) => {
        state.assignment = action.payload as Assignment
        state.apiStatus["getAssignment"] = ApiStatus.FULFILLED
      })
      .addCase(getAssignment.rejected, (state, action) => {
        state.apiError["getAssignment"] = action.payload as ApiError
        state.apiStatus["getAssignment"] = ApiStatus.REJECTED
      })

      .addCase(createAssignment.pending, (state, action) => {
        state.apiStatus["createAssignment"] = ApiStatus.PENDING
      })
      .addCase(createAssignment.fulfilled, (state, action) => {
        state.assignment = action.payload as Assignment
        state.apiStatus["createAssignment"] = ApiStatus.FULFILLED
      })
      .addCase(createAssignment.rejected, (state, action) => {
        state.apiError["createAssignment"] = action.payload as ApiError
        state.apiStatus["createAssignment"] = ApiStatus.REJECTED
      })

      .addCase(updateAssignment.pending, (state, action) => {
        state.apiStatus["updateAssignment"] = ApiStatus.PENDING
      })
      .addCase(updateAssignment.fulfilled, (state, action) => {
        state.assignment = action.payload as Assignment
        state.apiStatus["updateAssignment"] = ApiStatus.FULFILLED
      })
      .addCase(updateAssignment.rejected, (state, action) => {
        state.apiError["updateAssignment"] = action.payload as ApiError
        state.apiStatus["updateAssignment"] = ApiStatus.REJECTED
      })

      .addCase(deleteAssignment.pending, (state, action) => {
        state.apiStatus["deleteAssignment"] = ApiStatus.PENDING
      })
      .addCase(deleteAssignment.fulfilled, (state, action) => {
        state.apiStatus["deleteAssignment"] = ApiStatus.FULFILLED
      })
      .addCase(deleteAssignment.rejected, (state, action) => {
        state.apiError["deleteAssignment"] = action.payload as ApiError
        state.apiStatus["deleteAssignment"] = ApiStatus.REJECTED
      })

      // Students

      .addCase(submitStudentAnswers.pending, (state, action) => {
        state.apiStatus["submitStudentAnswers-" + action.meta.arg.object_id] =
          ApiStatus.PENDING
      })
      .addCase(submitStudentAnswers.fulfilled, (state, action) => {
        const studentAnswer = action.payload as StudentAnswer
        state.answers[studentAnswer.question] = studentAnswer
        state.apiStatus["submitStudentAnswers-" + action.meta.arg.object_id] =
          ApiStatus.FULFILLED
      })
      .addCase(submitStudentAnswers.rejected, (state, action) => {
        const { question, object_id } = action.meta.arg
        state.apiError[`submitStudentAnswers-${object_id}-${question}`] =
          action.payload as ApiError
        state.apiStatus[`submitStudentAnswers-${object_id}`] =
          ApiStatus.REJECTED
      })

      .addCase(listStudentAnswers.pending, (state, action) => {
        state.apiStatus["listStudentAnswers-" + action.meta.arg.object_id] =
          ApiStatus.PENDING
      })
      .addCase(listStudentAnswers.fulfilled, (state, action) => {
        const response = action.payload as ApiPaginatedResponse<StudentAnswer>
        const studentAnswers = response.results
        studentAnswers.forEach((answer) => {
          state.answers[`${action.meta.arg.object_id}-${answer.question}`] =
            answer
        })
        state.apiStatus["listStudentAnswers-" + action.meta.arg.object_id] =
          ApiStatus.FULFILLED
      })
      .addCase(listStudentAnswers.rejected, (state, action) => {
        state.apiError["listStudentAnswers" + action.meta.arg.question] =
          action.payload as ApiError
        state.apiStatus["listStudentAnswers-" + action.meta.arg.object_id] =
          ApiStatus.REJECTED
      })

      .addCase(listStudentProgress.pending, (state, action) => {
        state.apiStatus["listStudentProgress"] = ApiStatus.PENDING
      })
      .addCase(listStudentProgress.fulfilled, (state, action) => {
        state.progress = action.payload as ApiPaginatedResponse<StudentProgress>
        state.apiStatus["listStudentProgress"] = ApiStatus.FULFILLED
      })
      .addCase(listStudentProgress.rejected, (state, action) => {
        state.apiError["listStudentProgress"] = action.payload as ApiError
        state.apiStatus["listStudentProgress"] = ApiStatus.REJECTED
      })

      .addCase(getCourseStudentStats.pending, (state, action) => {
        state.apiStatus["getCourseStudentStats"] = ApiStatus.PENDING
      })
      .addCase(getCourseStudentStats.fulfilled, (state, action) => {
        state.courseStudentStats = action.payload as StudentStats
        state.apiStatus["getCourseStudentStats"] = ApiStatus.FULFILLED
      })
      .addCase(getCourseStudentStats.rejected, (state, action) => {
        state.apiError["getCourseStudentStats"] = action.payload as ApiError
        state.apiStatus["getCourseStudentStats"] = ApiStatus.REJECTED
      })

      // Submissions

      .addCase(listSubmissions.pending, (state, action) => {
        state.apiStatus[`listSubmissions-${action.meta.arg.assignmentId}`] =
          ApiStatus.PENDING
      })
      .addCase(listSubmissions.fulfilled, (state, action) => {
        state.submissions[action.meta.arg.assignmentId!] =
          action.payload as ApiPaginatedResponse<Submission>
        state.apiStatus[`listSubmissions-${action.meta.arg.assignmentId}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listSubmissions.rejected, (state, action) => {
        state.apiError[`listSubmissions-${action.meta.arg.assignmentId}`] =
          action.payload as ApiError
        state.apiStatus[`listSubmissions-${action.meta.arg.assignmentId}`] =
          ApiStatus.REJECTED
      })

      .addCase(submitAssignment.pending, (state, action) => {
        state.apiStatus["submitAssignment"] = ApiStatus.PENDING
      })
      .addCase(submitAssignment.fulfilled, (state, action) => {
        state.apiStatus["submitAssignment"] = ApiStatus.FULFILLED
      })
      .addCase(submitAssignment.rejected, (state, action) => {
        state.apiError["submitAssignment"] = action.payload as ApiError
        state.apiStatus["submitAssignment"] = ApiStatus.REJECTED
      })

      // hasJoinedCourse
      .addCase(hasJoinedCourse.pending, (state, action) => {
        state.apiStatus["hasJoinedCourse"] = ApiStatus.PENDING
      })
      .addCase(hasJoinedCourse.fulfilled, (state, action) => {
        state.joinedCourse = action.payload.status
        state.apiStatus["hasJoinedCourse"] = ApiStatus.FULFILLED
      })
      .addCase(hasJoinedCourse.rejected, (state, action) => {
        state.apiError["hasJoinedCourse"] = action.payload as ApiError
        state.apiStatus["hasJoinedCourse"] = ApiStatus.REJECTED
      })

      // hasRequestedCourse
      .addCase(hasRequestedCourse.pending, (state, action) => {
        state.apiStatus["hasRequestedCourse"] = ApiStatus.PENDING
      })
      .addCase(hasRequestedCourse.fulfilled, (state, action) => {
        state.requestedCourse = action.payload.status
        state.apiStatus["hasRequestedCourse"] = ApiStatus.FULFILLED
      })
      .addCase(hasRequestedCourse.rejected, (state, action) => {
        state.apiError["hasRequestedCourse"] = action.payload as ApiError
        state.apiStatus["hasRequestedCourse"] = ApiStatus.REJECTED
      })
  },
})

export default coursesSlice.reducer
