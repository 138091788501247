import axios from "axios"
import jwt_decode from "jwt-decode"
import Cookies from "universal-cookie"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { BASE_ENDPOINT, COOKIE_KEY } from "app-constants"
import { ApiStatus, ApiAction, ApiError, Auth, User } from "types"

const cookies = new Cookies()

export interface AccountsState {
  user: User
  otherUser: User
  auth: Auth
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: AccountsState = {
  user: {} as User,
  otherUser: {} as User,
  auth: {} as Auth,
  apiStatus: {},
  apiError: {},
}

export type LoginArgs = {
  email: string
  password: string
}

export const login = createAsyncThunk(
  "accounts/login",
  async (args: LoginArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/auth/token/`
    try {
      const response = await axios.post<Auth>(url, args)
      const jwtPayload: any = jwt_decode(response.data.access)
      const {
        exp,
        user_id,
        school_id,
        role,
        email_verified,
        is_active,
        is_staff,
      } = jwtPayload
      const userData = btoa(
        JSON.stringify({
          user_id,
          school_id,
          role,
          email_verified,
          is_active,
          is_staff,
        })
      )
      if (is_active) {
        cookies.set(COOKIE_KEY.access, response.data.access, { path: "/" })
        cookies.set(COOKIE_KEY.refresh, response.data.refresh, { path: "/" })
        cookies.set(COOKIE_KEY.expiresIn, exp, { path: "/" })
        cookies.set(COOKIE_KEY.user, userData, { path: "/" })
      }
      args.onFulfill({ item: { is_active, user_id, school_id } })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data as ApiError)
      return rejectWithValue(err?.response.data)
    }
  }
)

export type SocialLoginArgs = {
  access_token: string
  provider: string
}

export const socialLogin = createAsyncThunk(
  "accounts/socialLogin",
  async (args: SocialLoginArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/auth/social/${args.provider}/token/`
    try {
      const response = await axios.post<Auth>(url, {
        access_token: args.access_token,
      })
      const {
        access,
        refresh,
        school_id,
        role,
        email_verified,
        is_active,
        is_staff,
      } = response.data
      const jwtPayload: any = jwt_decode(access)
      const { exp, user_id } = jwtPayload
      const userData = btoa(
        JSON.stringify({
          user_id,
          school_id,
          role,
          email_verified,
          is_active,
          is_staff,
        })
      )
      if (is_active) {
        cookies.set(COOKIE_KEY.access, access, { path: "/" })
        cookies.set(COOKIE_KEY.refresh, refresh, { path: "/" })
        cookies.set(COOKIE_KEY.expiresIn, exp, { path: "/" })
        cookies.set(COOKIE_KEY.user, userData, { path: "/" })
      }
      args.onFulfill({ item: { is_active, user_id, school_id } })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data as ApiError)
      return rejectWithValue(err?.response.data)
    }
  }
)

export type CreateUserArgs = {
  first_name: string
  last_name: string
  title?: string
  email: string
  password: string
  role: string
  level?: string
  school: string
  accept_terms: boolean
  invite_token?: string
}

export const createUser = createAsyncThunk(
  "accounts/createUser",
  async (args: CreateUserArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/users/`
    try {
      const response = await axios.post<User>(url, args)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data as ApiError)
      return rejectWithValue(err?.response.data)
    }
  }
)

export type UpdateUserArgs = {
  id: string
  title: string
  first_name: string
  middle_name?: string
  last_name: string
  nickname?: string
  gender: string
  phone: string
  picture: string | Blob
  timezone: string
}

export const updateUser = createAsyncThunk(
  "accounts/updateUser",
  async (args: UpdateUserArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/users/${args.id}/`
    const formData = new FormData()
    for (const [key, value] of Object.entries(args)) {
      if (typeof value === "function") {
        continue
      }
      if (key === "middle_name" || key === "nickname") {
        formData.append(key, value || "")
      } else if (value) {
        formData.append(key, value)
      }
    }

    try {
      const response = await axios.patch<User>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailUser = {
  userId: string
}

export const getUser = createAsyncThunk(
  "accounts/getUser",
  async (args: DetailUser & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/users/${args.userId}/`
    try {
      const response = await axios.get<User>(url)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject()
      return rejectWithValue(err?.response.data)
    }
  }
)

export const getOtherUser = createAsyncThunk(
  "accounts/getOtherUser",
  async (args: DetailUser, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/users/${args.userId}/`
    try {
      const response = await axios.get<User>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response.data)
    }
  }
)

export type ChangePasswordArgs = {
  old_password: string
  new_password: string
}

export const changePassword = createAsyncThunk(
  "accounts/changePassword",
  async (args: ChangePasswordArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/change-password/`
    try {
      const response = await axios.patch(url, args)
      args.onFulfill({ id: response.data.message })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data)
      return rejectWithValue(err?.response.data)
    }
  }
)

type RequestPasswordResetArgs = {
  email: string
}

export const requestPasswordReset = createAsyncThunk(
  "accounts/requestPasswordReset",
  async (args: RequestPasswordResetArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/password-reset/`
    try {
      const response = await axios.post(url, args)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data)
      return rejectWithValue(err?.response.data)
    }
  }
)

export type ResetPasswordArgs = {
  password: string
  token: string | null
}

export const resetPassword = createAsyncThunk(
  "accounts/resetPassword",
  async (args: ResetPasswordArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/password-reset/`
    try {
      const response = await axios.patch(url, args)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response.data)
      return rejectWithValue(err?.response.data)
    }
  }
)

type VerifyAccountProps = {
  token: string
  activate: boolean
}

export const verifyAccount = createAsyncThunk(
  "accounts/verifyAccount",
  async (args: VerifyAccountProps, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/verify-account/`
    try {
      const response = await axios.post<User>(url, args)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response.data)
    }
  }
)

type ActivateAccountProps = {
  token: string
  user_id: string
}

export const activateAccount = createAsyncThunk(
  "accounts/activateAccount",
  async (args: ActivateAccountProps, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/accounts/activate-account/`
    try {
      const response = await axios.post(url, args)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response.data)
    }
  }
)

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    logout: (state, action) => {
      cookies.set(COOKIE_KEY.redirectUrl, action?.payload?.redirectUrl, {
        path: "/",
      })
      cookies.remove(COOKIE_KEY.access, { path: "/" })
      cookies.remove(COOKIE_KEY.refresh, { path: "/" })
      cookies.remove(COOKIE_KEY.expiresIn, { path: "/" })
      cookies.remove(COOKIE_KEY.user, { path: "/" })
      delete axios.defaults.headers.common["Authorization"]
      state.auth = {} as Auth
      state.apiStatus = {}
    },
    wrongEmail: (state) => {
      state.apiStatus["resetPassword"] = ApiStatus.IDLE
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.apiStatus["login"] = ApiStatus.PENDING
      })
      .addCase(login.fulfilled, (state, action) => {
        state.auth = action.payload as Auth
        state.apiStatus["login"] = ApiStatus.FULFILLED
      })
      .addCase(login.rejected, (state, action) => {
        state.apiError["login"] = action.payload as ApiError
        state.apiStatus["login"] = ApiStatus.REJECTED
      })

      .addCase(socialLogin.pending, (state, action) => {
        state.apiStatus["socialLogin"] = ApiStatus.PENDING
      })
      .addCase(socialLogin.fulfilled, (state, action) => {
        state.auth = action.payload as Auth
        state.apiStatus["socialLogin"] = ApiStatus.FULFILLED
      })
      .addCase(socialLogin.rejected, (state, action) => {
        state.apiError["socialLogin"] = action.payload as ApiError
        state.apiStatus["socialLogin"] = ApiStatus.REJECTED
      })

      .addCase(createUser.pending, (state, action) => {
        state.apiStatus["createUser"] = ApiStatus.PENDING
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.user = action.payload as User
        state.apiStatus["createUser"] = ApiStatus.FULFILLED
      })
      .addCase(createUser.rejected, (state, action) => {
        state.apiError["createUser"] = action.payload as ApiError
        state.apiStatus["createUser"] = ApiStatus.REJECTED
      })

      .addCase(updateUser.pending, (state, action) => {
        state.apiStatus["updateUser"] = ApiStatus.PENDING
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload as User
        state.apiStatus["updateUser"] = ApiStatus.FULFILLED
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.apiError["updateUser"] = action.payload as ApiError
        state.apiStatus["updateUser"] = ApiStatus.REJECTED
      })

      .addCase(getUser.pending, (state, action) => {
        state.apiStatus["getUser"] = ApiStatus.PENDING
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload as User
        state.apiStatus["getUser"] = ApiStatus.FULFILLED
      })
      .addCase(getUser.rejected, (state, action) => {
        state.apiError["getUser"] = action.payload as ApiError
        state.apiStatus["getUser"] = ApiStatus.REJECTED
      })

      .addCase(getOtherUser.pending, (state, action) => {
        state.apiStatus["getOtherUser"] = ApiStatus.PENDING
      })
      .addCase(getOtherUser.fulfilled, (state, action) => {
        state.otherUser = action.payload as User
        state.apiStatus["getOtherUser"] = ApiStatus.FULFILLED
      })
      .addCase(getOtherUser.rejected, (state, action) => {
        state.apiError["getOtherUser"] = action.payload as ApiError
        state.apiStatus["getOtherUser"] = ApiStatus.REJECTED
      })

      .addCase(changePassword.pending, (state, action) => {
        state.apiStatus["changePassword"] = ApiStatus.PENDING
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.apiStatus["changePassword"] = ApiStatus.FULFILLED
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.apiError["changePassword"] = action.payload as ApiError
        state.apiStatus["changePassword"] = ApiStatus.REJECTED
      })

      .addCase(requestPasswordReset.pending, (state, action) => {
        state.apiStatus["requestPasswordReset"] = ApiStatus.PENDING
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.apiStatus["requestPasswordReset"] = ApiStatus.FULFILLED
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.apiError["requestPasswordReset"] = action.payload as ApiError
        state.apiStatus["requestPasswordReset"] = ApiStatus.REJECTED
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.apiStatus["resetPassword"] = ApiStatus.PENDING
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.apiStatus["resetPassword"] = ApiStatus.FULFILLED
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.apiError["resetPassword"] = action.payload as ApiError
        state.apiStatus["resetPassword"] = ApiStatus.REJECTED
      })

      .addCase(verifyAccount.pending, (state, action) => {
        state.apiStatus["verifyAccount"] = ApiStatus.PENDING
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.user = action.payload as User
        state.apiStatus["verifyAccount"] = ApiStatus.FULFILLED
      })
      .addCase(verifyAccount.rejected, (state, action) => {
        state.apiError["verifyAccount"] = action.payload as ApiError
        state.apiStatus["verifyAccount"] = ApiStatus.REJECTED
      })
  },
})

type RefreshTokenArgs = {
  onResolve?: () => void
  onReject?: () => void
}

export const refreshToken = async ({
  onResolve,
  onReject,
}: RefreshTokenArgs) => {
  const refreshToken = cookies.get(COOKIE_KEY.refresh)
  const url = `${BASE_ENDPOINT}/accounts/auth/token/refresh/`
  delete axios.defaults.headers.common["Authorization"]
  try {
    const response = await axios.post<Auth>(url, { refresh: refreshToken })
    const jwtPayload: any = jwt_decode(response.data.access)
    const { exp } = jwtPayload
    cookies.set(COOKIE_KEY.access, response.data.access, { path: "/" })
    cookies.set(COOKIE_KEY.expiresIn, exp, { path: "/" })
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.access}`
    onResolve && onResolve()
  } catch (err: any) {
    onReject && onReject()
  }
}

export const isAuthenticated = (): boolean => {
  // Check whether the current time is past the access token's expiry time
  const currentTime = Math.round(new Date().getTime() / 1000)
  const accessToken = cookies.get(COOKIE_KEY.access)
  const expiresIn = cookies.get(COOKIE_KEY.expiresIn)
  if (!accessToken || !expiresIn || currentTime > Number(expiresIn)) {
    return false
  }

  return currentTime < Number(expiresIn)
}

export const { logout, wrongEmail } = accountsSlice.actions

export default accountsSlice.reducer
