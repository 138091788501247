import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

import { accountsApi } from "../features/accounts/accountsApi"
import accountsReducer from "../features/accounts/accountsSlice"
import commsReducer from "../features/comms/commsSlice"
import coursesReducer from "../features/courses/coursesSlice"
import navigationReducer from "../features/navigation/navigationSlice"
import notificationsReducer from "../features/notifications/notificationsSlice"
import paymentsReducer from "../features/payments/paymentsSlice"
import schoolsReducer from "../features/schools/schoolsSlice"
import timetableReducer from "../features/timetable/timetableSlice"

export const createStore = () =>
  configureStore({
    reducer: {
      [accountsApi.reducerPath]: accountsApi.reducer,
      accounts: accountsReducer,
      comms: commsReducer,
      courses: coursesReducer,
      navigation: navigationReducer,
      notifications: notificationsReducer,
      payments: paymentsReducer,
      schools: schoolsReducer,
      timetable: timetableReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(accountsApi.middleware),
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
