import axios from "axios"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "app-constants"
import { ApiStatus, ApiError, Occurrence } from "types"

export interface TimetableState {
  occurrences: { [calendarView: string]: Occurrence[] }
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: TimetableState = {
  occurrences: {},
  apiStatus: {},
  apiError: {},
}

// Occurrences

type GetOccurrencesArgs = {
  view: string
  calendar_id: number
  start: string
  end: string
  timezone?: string
  include_students?: boolean
}

export const listOccurrences = createAsyncThunk(
  "timetable/listOccurrences",
  async (args: GetOccurrencesArgs, { rejectWithValue }) => {
    const { calendar_id, start, end, timezone, include_students } = args
    const url = `${BASE_ENDPOINT}/timetable/calendars/${calendar_id}/occurrences/`
    try {
      const response = await axios.get<Occurrence[]>(url, {
        params: { start, end, timezone, include_students },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const timetableSlice = createSlice({
  name: "timetable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listOccurrences.pending, (state, action) => {
        state.apiStatus["listOccurrences-" + action.meta.arg.view] =
          ApiStatus.PENDING
      })
      .addCase(listOccurrences.fulfilled, (state, action) => {
        state.occurrences[action.meta.arg.view] = action.payload as Occurrence[]
        state.apiStatus["listOccurrences-" + action.meta.arg.view] =
          ApiStatus.FULFILLED
      })
      .addCase(listOccurrences.rejected, (state, action) => {
        state.apiError["listOccurrences-" + action.meta.arg.view] =
          action.payload as ApiError
        state.apiStatus["listOccurrences-" + action.meta.arg.view] =
          ApiStatus.REJECTED
      })
  },
})

export default timetableSlice.reducer
