import axios from "axios"
import Cookies from "universal-cookie"

import { EnhancedStore } from "@reduxjs/toolkit"

import { COOKIE_KEY } from "app-constants"

const cookies = new Cookies()

const AxiosInterceptors = {
  setupInterceptors: (store: EnhancedStore<any>) => {
    // Request interceptors
    axios.interceptors.request.use(
      (config) => {
        const accessToken =
          store.getState().accounts.auth.access ||
          cookies.get(COOKIE_KEY.access)
        if (accessToken && config?.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
}

export default AxiosInterceptors
