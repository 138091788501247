import { useState } from "react"

import { useCookies } from "react-cookie"

import { COOKIE_KEY } from "app-constants"
import { useGetUserQuery } from "features/accounts/accountsApi"
import { User } from "types"

export const useUser = () => {
  const [cookies] = useCookies([COOKIE_KEY.user])
  const loggedInUser = JSON.parse(atob(cookies[COOKIE_KEY.user] || "") || "{}")
  const [userId] = useState(loggedInUser?.user_id)
  const [isStaff] = useState(loggedInUser?.is_staff)
  const [isStudent] = useState(Boolean(loggedInUser?.role === "student"))
  const [isTeacher] = useState(Boolean(loggedInUser?.role === "teacher"))
  const [isAdmin] = useState(Boolean(loggedInUser?.role === "admin"))
  const [schoolId] = useState(loggedInUser?.school_id)

  const { data, error, isLoading } = useGetUserQuery(userId)
  const user = (data || {}) as User

  return {
    user,
    error,
    isLoading,
    isStaff,
    isStudent,
    isTeacher,
    isAdmin,
    schoolId,
  }
}
