import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import "flatpickr/dist/themes/light.css"
import { CookiesProvider } from "react-cookie"
import { createRoot } from "react-dom/client"
import "react-lazy-load-image-component/src/effects/blur.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import "react-phone-number-input/style.css"

import App from "./App"
import "./assets/@coreui/coreui.scss"
import "./assets/scss/App.scss"
import AxiosInterceptors from "./features/common/AxiosInterceptors"
import reportWebVitals from "./reportWebVitals"
// import * as serviceWorker from "./serviceWorker"
import { store } from "./store"

AxiosInterceptors.setupInterceptors(store)

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_MOCKS === "true"
) {
  const { worker } = require("./mocks/browser")
  worker.start()
}

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
)

// MSW doesn't play along CRA service worker
// Create React App unregisters all Service Workers by default,
// which would also unregister the mock Service Worker,
// resulting into broken requests interception.
// https://mswjs.io/docs/getting-started/integrate/browser#create-react-app-version-3

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
