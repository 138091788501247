import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import type { RootState, AppDispatch } from "../store"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { useInterval } from "./useInterval"
export { useQuery } from "./useQuery"
export { useUser } from "./useUser"
export { useAutoComplete } from "./useAutoComplete"
