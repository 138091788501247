import React, { Suspense } from "react"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"

import { isAuthenticated, logout } from "features/accounts/accountsSlice"
import { useAppDispatch } from "hooks"
import { AppSpinner } from "utils"

const DashboardLayout = React.lazy(() => import("layouts/DashboardLayout"))
const WebsiteLayout = React.lazy(() => import("layouts/WebsiteLayout"))

const App = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const PrivateRoute = ({ children }: any) => {
    if (!isAuthenticated()) {
      dispatch(logout({ redirectUrl: location.pathname }))
      return <Navigate to="/sign-in" />
    } else {
      return children
    }
  }

  return (
    <Suspense fallback={<AppSpinner />}>
      <Routes>
        <Route
          path="/a/*"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<WebsiteLayout />} />
      </Routes>
    </Suspense>
  )
}

export default App
