// Types
import { XOR } from "ts-xor"

export type ApiPaginatedResponse<T> = {
  count: number
  start_index: number
  end_index: number
  next: string
  previous: string
  results: T[]
}

export enum ErrorType {
  AUTHENTICATION_ERROR = "authentication_error",
  INVALID_REQUEST = "invalid_request",
  MULTIPLE = "multiple",
  SERVER_ERROR = "server_error",
  THROTTLED_ERROR = "throttled_error",
  VALIDATION_ERROR = "validation_error",
}

export type ApiError = {
  type: ErrorType
  code: string
  detail: string
  attr: string | null
  list: string | null
}

export type objectType = {
  [key: string]: any
}

export type TypeCrossFunction = {
  onLogout: () => void
}

export type IFulfillParams = {
  id?: string
  item?: any
}

export type ApiAction = {
  onFulfill: (args?: IFulfillParams) => void
  onReject: (error?: ApiError) => void
}

export type Alert = {
  type: string
  message?: string
  timestamp?: number
}

export type SOption = {
  label: string
  value: string
}

export type ChangeAddressDTO = {
  visible: boolean
  action: ChangeAction
  address: XOR<BillingAddress, PhysicalAddress> | null
}

export type Filter = {
  [key: string]: string | object
}

// Enums

export enum ApiStatus {
  IDLE = "idle",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

export enum ChangeAction {
  CREATE = "create",
  UPDATE = "update",
}

export enum AddressType {
  BILLING = "billing",
  PHYSICAL = "physical",
}

// Accounts

export type Auth = {
  refresh: string
  access: string
  is_active?: boolean
  is_staff?: boolean
  role?: string
  email_verified?: string
  school_id?: string
}

export type AuthLoginParams = {
  is_active: string
  user_id: string
  school_id: string
}

export type User = {
  id: string
  title: string
  first_name: string
  middle_name?: string
  last_name: string
  nickname?: string
  gender: string
  email: string
  email_verified: boolean
  phone: string
  picture: string
  role: UserRole
  accepted_terms: boolean
  timezone: string
  calendar: number
  is_staff: boolean
  is_active: boolean
  last_login: string
  student: Student | null
  teacher: Teacher | null
  billing_addresses: BillingAddress[]
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export enum UserRole {
  STUDENT = "student",
  TEACHER = "teacher",
  ADMIN = "admin",
}

// Schools

export type PhysicalAddress = {
  id?: string
  address_1: string
  address_2?: string
  city: string
  state: string
  country: string
  postal_code: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type School = {
  id: string
  name: string
  logo: string
  phone: string
  email: string
  address?: string
  moto?: string
  about?: string
  terms_and_privacy?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Level = {
  id: string
  name: string
  description: string
  school: string | School
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Student = {
  user: string
  name: string
  picture: string
  level: Level
  address: PhysicalAddress
  school: School
}

export type AttendanceStat = {
  total: number
  attendend: number
}

export type StudentStats = {
  attendances: AttendanceStat
}

export type Teacher = {
  user: string
  name: string
  first_name?: string
  last_name?: string
  short_name?: string
  picture: string
  job_position: string
  bio: string
  address: PhysicalAddress
  address_id?: string
  hourly_charge_rate: number
  hourly_charge_rate_currency: string
  reviews?: Feedback[]
  total_reviews?: number
  rating?: string
  courses?: string
  schedule?: AvailabilitySchedule
  qualifications?: Qualification[]
  verified?: boolean
  date_verified?: string
  school: School
}

export type Lesson = {
  id?: string
  name: string
  description: string
  presentation: string | File
  logout_url?: string
  start_date?: string
  duration: number
  end_date?: string
  repeat_rule?: string
  repeat_until?: string
  color?: string
  course?: Course
  course_id?: string
  school?: School
  school_id?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type StudentAttendance = {
  id?: number
  student: string
  occurrence: number
  lesson: string
  course: string
  school: string
  start_date: string
  end_date: string
}

export type Attachment = {
  id?: string
  name: string
  file: string | File
}

export enum StudentRequestStatus {
  ACCEPTED = "accepted",
  DECLINED = "declined",
  SUSPENDED = "suspended",
  PENDING = "pending",
}

export type StudentRequest = {
  id?: string
  student?: Student
  student_id?: string
  message?: string
  attachments?: Attachment[]
  attachments_ids?: string[]
  teacher?: Teacher
  teacher_id?: string
  timezone?: string
  response?: string
  course?: Course
  course_id?: string
  school?: School
  school_id?: string
  status?: StudentRequestStatus
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Qualification = {
  id?: string
  school?: string
  teacher: string
  institution: string
  course: string
  level: any
  certificate: string | File
  year_attained: string
}

export type AvailabilitySchedule = {
  id?: string
  teacher: string
  sunday?: Schedule[]
  sunday_ids?: string
  monday?: Schedule[]
  monday_ids?: string
  tuesday?: Schedule[]
  tuesday_ids?: string
  wednesday?: Schedule[]
  wednesday_ids?: string
  thursday?: Schedule[]
  thursday_ids?: string
  friday?: Schedule[]
  friday_ids?: string
  saturday?: Schedule[]
  saturday_ids?: string
}

export type Schedule = {
  id: string
  name: string
  start_time: string
  end_time: string
}

// Courses

export type Course = {
  id?: string
  name: string
  description: string
  cover: string | File
  subject?: string
  teacher?: Teacher
  teacher_id?: string
  assistant_teachers?: Teacher[]
  assistant_teachers_ids?: string[]
  students?: Student[]
  students_ids?: string[]
  levels?: Level[]
  levels_ids?: string[]
  is_elective: boolean
  school?: string
  hourly_charge_rate?: number
  hourly_charge_rate_currency?: string
  topics?: Topic[]
  lessons?: string[]
  stats?: CourseStatistics
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Topic = {
  id?: string
  name: string
  description: string
  notes: string | File
  course: string
  parent_topic: string
  position?: number
  subtopics?: Topic[]
  quizzes?: Quiz[]
  exercises?: Exercise[]
  tests?: Test[]
  assignments?: Assignment[]
  read_time?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type PageCategory = {
  id?: string
  name: string
  description: string
  parent_category: string
  subcategories?: PageCategory[]
  course: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Page = {
  id?: string
  name: string
  description: string
  category?: string
  parent_page?: string
  subpages?: Page[]
  course: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type MultipleChoice = {
  id?: string
  choice: string
  hint?: string
  correct_answer?: boolean
}

export enum AnswerInputType {
  TEXT = "text",
  NUMBER = "number",
  RADIO = "radio",
  CHECKBOX = "checkbox",
}

export type Question = {
  id?: string
  question: string
  answer_input_type: AnswerInputType
  multiple_choices: MultipleChoice[]
  marks: number
  course?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Quiz = {
  id?: string
  name: string
  question?: Question
  question_id?: string
  maximum_attempts: number
  topic: string
  course: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type OrderedQuestion = {
  question?: Question
  question_id?: string
  position: number
}

export type Exercise = {
  id?: string
  name: string
  questions?: OrderedQuestion[]
  maximum_attempts: number
  topic: string
  course: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Test = {
  id?: string
  name: string
  questions?: OrderedQuestion[]
  maximum_attempts: number
  topic: string
  course: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Assignment = {
  id?: string
  name: string
  questions?: OrderedQuestion[]
  due_date: string
  total_marks?: number
  topic: any
  course: any
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export enum StudentAnswerStatus {
  NOT_MARKED = "not_marked",
  INCORRECT = "incorrect",
  CORRECT = "correct",
}

export type StudentAnswer = {
  id?: string
  student: string
  question: string
  course: string
  maximum_attempts: number
  multiple_choice_answers?: string[]
  text_answer?: string
  status?: StudentAnswerStatus
  feedback?: string
  content_type?: string
  object_id?: string
}

export type StudentProgress = {
  id: string
  student: string
  course: string
  topics_covered: string[]
  coverage: number
  proficiency: number
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Submission = {
  id?: string
  student: string
  assignment: string
  text_submission: string
  file_submission: string | File
  course: string
  marked?: boolean
  marks?: number
  marks_percentage?: number
  grade?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type CourseStatistics = {
  course_length: number
  students_count: number
}

// Timetable

export type Event = {
  id: number
  start: string
  end: string
  lesson: string
  rule: Rule
  end_recurring_period: string
  color: string
}

export enum Frequency {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
  MINUTELY = "MINUTELY",
  SECONDLY = "SECONDLY",
}

export type Rule = {
  id: number
  name: string
  description: string
  frequency: Frequency
  params: string
  rrule_frequency: string
}

export type Occurrence = {
  id: string
  title: string
  description: string
  start: string
  end: string
  existed: boolean
  event_id: string
  lesson: Lesson
  course: {
    name: string
    teacher: string
    students?: Student[]
  }
  color: string
  rule: string
  end_recurring_period: string
  created_by: string
  cancelled: string
}

// Chats

export type GroupChat = {
  id?: string
  name: string
  description: string
  icon: string | File
  members_ids?: string[]
  members?: ChatMember[]
  attachments?: string[]
  school: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Chat = {
  id?: string
  message: string
  attachment?: string | File
  sender: string
  sender_name?: string
  sender_picture?: string
  recipient?: string
  recipient_name?: string
  recipient_picture?: string
  group?: string
  group_name?: string
  group_icon?: string
  school?: string
  parent_chat?: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type OnlineStatus = {
  [userId: string]: boolean
}

export type ChatStatus = {
  status: OnlineStatus
  users: string[]
}

export type ChatMember = {
  id: string
  first_name: string
  middle_name: string
  last_name: string
  picture: string
}

export enum FeedbackCategory {
  TEACHERS = "teachers",
  UNCATEGORIZED = "uncategorized",
}

export type Feedback = {
  id?: string
  title: string
  description: string
  rating: number
  attachment?: string
  category: FeedbackCategory
  sender: string
  recipient: string
  course: string
  school: string
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

// Payments

export type BillingAddress = {
  id?: string
  user: string
  address_1: string
  address_2?: string
  city: string
  state: string
  country: string
  postal_code: string
  phone: string
  active: boolean
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

export type Tax = {
  id: string
  country: string
  rate: number
  date_created?: string
}

export type LevelPaymentConfig = {
  level: string
  price: string
  price_currency: string
}

export type CoursePaymentConfig = {
  course: string
  price: string
  price_currency: string
}

export type PaymentConfig = {
  id: string
  name: string
  category: string
  levels: LevelPaymentConfig[]
  courses: CoursePaymentConfig[]
  frequency: number
  frequency_step: number
  charge_date: string
  end_recurring_period: string
  date_created: string
}

export enum InvoiceStatus {
  PAID = "paid",
  NOT_PAID = "not_paid",
  PARTIALLY_PAID = "partially_paid",
  CANCELED = "canceled",
}

export type Invoice = {
  id: string
  reference: string
  billing_address: string
  items: InvoiceItem[]
  taxable: boolean
  date_due: string
  currency: string
  total_amount: number
  total_amount_taxable: number
  total_amount_paid: number
  amount_due: number
  is_overdue: boolean
  status: InvoiceStatus
  date_created?: string
}

export type InvoiceItem = {
  id: number
  description: string
  quantity: number
  price: number
  currency: string
  total_price: number
  content_type?: string
  object_id?: string
  date_created?: string
}

export enum TransactionStatus {
  NOT_CHARGED = "not_charged",
  PARTIALLY_CHARGED = "partially_charged",
  FULLY_CHARGED = "fully_charged",
  PARTIALLY_REFUNDED = "partially_refunded",
  FULLY_REFUNDED = "fully_refunded",
  EXCESS = "excess",
}

export enum PaymentOption {
  CARD = "card",
  MPESA = "mpesa",
}

export type Transaction = {
  id: string
  invoice: Invoice
  reference: string
  customer_ip_address: string
  amount: number
  amount_captured: number
  amount_refunded: number
  currency: string
  status: TransactionStatus
  payment_option: PaymentOption
  extra_data: any
  date_created: string
}

// Notifications

export enum Activity {
  CHAT = "chat",
  UPDATE = "update",
  REQUEST = "request",
}

export type Notification = {
  id: string
  activity: Activity
  title?: string
  description?: string
  sender: string
  sender_name?: string
  sender_picture?: string
  recipients: string[]
  read: string[]
  date_created?: string
  created_by?: string
  date_modified?: string
  modified_by?: string
}

// Subject

export type Subject = {
  id?: string
  name: string
  description: string
  school: string
}

export type StatusResponse = {
  status: boolean
}
